import {
   Alert,
   AlertDescription,
   AlertTitle,
   Badge,
   Box,
   Button,
   Drawer,
   DrawerBody,
   DrawerCloseButton,
   DrawerContent,
   DrawerFooter,
   DrawerHeader,
   DrawerOverlay,
   Flex,
   Heading,
   HStack,
   SimpleGrid,
   Skeleton,
   Spinner,
   Text,
   VisuallyHidden,
   VStack,
} from '@chakra-ui/react';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { executeNextLoop, formatMoney } from '@ifixit/helpers';
import { FaIcon, FlagCountryCode } from '@ifixit/icons';
import { useCart } from '@ifixit/shopify-cart-sdk/hooks';
import { type LanguageCode } from '@ifixit/shopify-storefront-client';
import { useEffect, useState, type ElementType } from 'react';
import { Collapse, Fade, Slide } from '../../animations';
import { useIsMountedState } from '../../hooks';
import { CartLineItems } from '../cart-line-items';
import { CrossSell } from '../cross-sell';
import type { CartImageComponent } from '../types';
import { CartDrawerTrigger } from './CartDrawerTrigger';
import { CartEmptyState } from './CartEmptyState';
import { useCartDrawer } from './hooks/useCartDrawer';
import { ShopifyCheckoutButton } from './shopify-checkout-button';
import { LocaleCode, useTranslations } from '@ifixit/i18n';
import { MerchBanner } from '../../merch/merch-banner';
import { FreeShippingBanner } from '../../commerce/free-shipping-banner';
import { usePriceTier } from '@ifixit/app/hooks';
import { useCrossSellItems } from '../cross-sell/hooks/use-cross-sell-items';

interface CartDrawerProps {
   ImageComponent: CartImageComponent;
   linkComponent?: ElementType;
   supportLinkComponent: ElementType;
   language: LanguageCode;
   localeCode: LocaleCode;
   flagCode?: FlagCountryCode;
   viewCartUrl: string;
   storeCode: string;
   showMerchBanner?: boolean;
   recordMerchExperiment: () => boolean;
}

export function CartDrawer({
   ImageComponent,
   linkComponent,
   language,
   localeCode,
   supportLinkComponent: SupportLinkComponent,
   viewCartUrl,
   storeCode,
   recordMerchExperiment,
}: CartDrawerProps) {
   const { isOpen, onOpen, onClose, onViewCart } = useCartDrawer({ language });
   const isMounted = useIsMountedState();
   const cart = useCart({ language });
   const t = useTranslations();
   const { priceTier } = usePriceTier({ storeCode });
   const crossSellItems = useCrossSellItems({ language, shouldQuery: isOpen });
   const [showMerchBanner, setShowMerchBanner] = useState(false);
   const isCartEmpty = cart.isFetched && !cart.data?.hasItemsInCart;
   useEffect(() => {
      if (isMounted && isOpen) {
         setShowMerchBanner(recordMerchExperiment());
      }
   }, [isMounted, isOpen]);

   return (
      <>
         <CartDrawerTrigger
            onClick={() => executeNextLoop(onOpen)}
            hasItemsInCart={cart.data?.hasItemsInCart}
         />
         {isMounted && (
            <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
               <DrawerOverlay />
               <DrawerContent
                  color="gray.800"
                  data-testid="cart-drawer"
                  fontSize="md"
                  maxWidth={{ base: 'calc(100vw - 60px)', sm: '436px' }}
               >
                  <DrawerHeader borderBottomWidth={1} position="relative" px={3}>
                     <DrawerCloseButton
                        top="50%"
                        transform="translateY(-50%)"
                        data-testid="cart-drawer-close"
                        color="#a3a3a3"
                        fontSize="12px"
                     />
                     <HStack align="center">
                        <Heading fontSize="16px" lineHeight="normal" my="0">
                           {t('CartPage.shoppingCart')}
                        </Heading>
                        {(cart.data != null || !cart.isError) && (
                           <Badge
                              borderRadius="full"
                              variant="subtle"
                              colorScheme="gray"
                              boxSize="6"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              bg="gray.200"
                              color="gray.400"
                           >
                              {cart.isLoading ? (
                                 <Spinner size="xs" />
                              ) : (
                                 <>
                                    <VisuallyHidden>{t('CartPage.numItems')}</VisuallyHidden>
                                    <Box
                                       as="span"
                                       data-testid="cart-drawer-item-count"
                                       color="gray.500"
                                       fontWeight="700"
                                    >
                                       {cart.data?.totals.itemsCount ?? 0}
                                    </Box>
                                 </>
                              )}
                           </Badge>
                        )}
                     </HStack>
                  </DrawerHeader>

                  <DrawerBody p={3} data-testid="cart-drawer-body" position="relative">
                     <VStack gap={3} align="unset">
                        {cart.isError && (
                           <Alert
                              status="error"
                              variant="subtle"
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                              textAlign="center"
                              height="200px"
                           >
                              <FaIcon icon={faCircleExclamation} h="10" color="red.500" />
                              <AlertTitle mt={3} mb={1} fontSize="lg">
                                 {t('CartPage.Error.fetch')}
                              </AlertTitle>
                              <AlertDescription maxWidth="sm">
                                 {t.rich('CartPage.Error.reload', {
                                    link: chunks => (
                                       <SupportLinkComponent>{chunks}</SupportLinkComponent>
                                    ),
                                 })}
                              </AlertDescription>
                           </Alert>
                        )}
                        {cart.data?.hasItemsInCart && (
                           <>
                              <FreeShippingBanner
                                 language={language}
                                 localeCode={localeCode}
                                 storeCode={storeCode}
                                 priceTier={priceTier}
                              />
                              {showMerchBanner && (
                                 <MerchBanner
                                    analytics={{ descriptor: 'Cart Drawer Merch' }}
                                    ImageComponent={ImageComponent}
                                    linkComponent={linkComponent}
                                    language={language}
                                    localeCode={localeCode}
                                 />
                              )}
                              <CartLineItems
                                 cart={cart.data}
                                 testIdPrefix="cart-drawer"
                                 borderBottomWidth={1}
                                 ImageComponent={ImageComponent}
                                 linkComponent={linkComponent}
                                 language={language}
                                 localeCode={localeCode}
                              />
                           </>
                        )}
                        <Fade show={isCartEmpty} disableExitAnimation w="full">
                           <CartEmptyState onClose={onClose} />
                           {showMerchBanner && (
                              <Box>
                                 <MerchBanner
                                    analytics={{ descriptor: 'Cart Drawer Merch' }}
                                    ImageComponent={ImageComponent}
                                    linkComponent={linkComponent}
                                    language={language}
                                    localeCode={localeCode}
                                 />
                              </Box>
                           )}
                        </Fade>
                        <Box data-testid="cart-drawer-x-sell-items">
                           <CrossSell
                              analytics={{ descriptor: 'Cart Drawer Cross Sell' }}
                              crossSellItems={crossSellItems}
                              spacing="3"
                              ImageComponent={ImageComponent}
                              linkComponent={linkComponent}
                              language={language}
                              localeCode={localeCode}
                           />
                        </Box>
                     </VStack>
                  </DrawerBody>

                  <Slide show={cart.data?.hasItemsInCart}>
                     <DrawerFooter
                        borderTopWidth={1}
                        p={3}
                        display={cart.data?.hasItemsInCart ? 'flex' : 'none'}
                     >
                        <Box w="full">
                           <Collapse show={!cart.isError} mb="3">
                              <Flex w="full" justify="space-between">
                                 <Text fontSize="14px" fontWeight="bold">
                                    Total
                                 </Text>
                                 <Flex direction="column" align="flex-end">
                                    {cart.data && !cart.isRefetching ? (
                                       <Text
                                          fontSize="20px"
                                          lineHeight="1em"
                                          fontWeight="bold"
                                          my="0"
                                       >
                                          {formatMoney(cart.data.totals.price, localeCode)}
                                       </Text>
                                    ) : (
                                       <Skeleton h="20px" w="80px" />
                                    )}
                                 </Flex>
                              </Flex>
                           </Collapse>
                           <SimpleGrid columns={2} spacing="2.5" w="full">
                              <Button
                                 as="a"
                                 href={viewCartUrl}
                                 variant="solid"
                                 bgColor="gray.200"
                                 fontSize="16px"
                                 onClick={onViewCart}
                                 _hover={{ bgColor: 'gray.300' }}
                              >
                                 {t('CartPage.viewCart')}
                              </Button>
                              <ShopifyCheckoutButton language={language} />
                           </SimpleGrid>
                        </Box>
                     </DrawerFooter>
                  </Slide>
               </DrawerContent>
            </Drawer>
         )}
      </>
   );
}
