import { Box, BoxProps, Divider, VStack } from '@chakra-ui/react';
import { type Cart } from '@ifixit/shopify-cart-sdk';
import { useRemoveLineItem } from '@ifixit/shopify-cart-sdk/hooks';
import { type LanguageCode } from '@ifixit/shopify-storefront-client';
import type { ElementType } from 'react';
import { CartLineItem } from './drawer/CartLineItem';
import type { CartImageComponent } from './types';
import { LocaleCode, useTranslations } from '@ifixit/i18n';

export type CartLineItemsProps = BoxProps & {
   cart: Cart;
   testIdPrefix: string;
   ImageComponent: CartImageComponent;
   linkComponent?: ElementType;
   language: LanguageCode;
   localeCode: LocaleCode;
};

export function CartLineItems({
   cart,
   testIdPrefix,
   ImageComponent,
   linkComponent,
   language,
   localeCode,
   ...otherProps
}: CartLineItemsProps) {
   const t = useTranslations();
   const { removeLineItem, enabled: removeLineItemEnabled } = useRemoveLineItem({ language });

   return (
      <Box
         data-testid={`${testIdPrefix}-line-items`}
         borderRadius="lg"
         borderWidth={1}
         overflow="hidden"
         {...otherProps}
      >
         <VStack spacing="0" align="stretch" divider={<Divider />} listStyleType="none">
            {cart.lineItems.map(item => (
               <CartLineItem
                  key={item.itemcode}
                  lineItem={item}
                  onRemove={() => removeLineItem.mutate({ line: item, analytics: { localeCode } })}
                  removeDisabled={!removeLineItemEnabled}
                  ImageComponent={ImageComponent}
                  linkComponent={linkComponent}
                  language={language}
                  localeCode={localeCode}
                  ariaDecrease={t('CartPage.ariaDecrease')}
                  ariaIncrease={t('CartPage.ariaIncrease')}
                  notEnoughInventory={t('ErrorHandling.notEnoughInventory')}
                  outOfStock={t('ErrorHandling.outOfStock')}
                  unableToUpdateQuantity={t('ErrorHandling.unableToUpdateQuantity')}
               />
            ))}
         </VStack>
      </Box>
   );
}
